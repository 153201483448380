<template>
  <div class="flex flex-col">
    <div id="hardware-category-products" class="flex-1 hardware-category-products-container">

      <!-- BREADCRUMB -->
      <breadcrumb-base :breadcrumb="breadcrumb" :title="'Hardware'">
        <template v-slot:right-end>
          <div class="vx-row">
            <div class="p-3"></div>
            <button
              @click="openAddCategoryPage"
              class="text-white flex items-center space-x-2 bg-primary hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              type="button">
              <span class="text-2xl">+</span><span>{{ $t('AddCategory') }}</span>
            </button>

          </div>
        </template>
      </breadcrumb-base>

      <!--    Empty List State -->
      <transition name="fade">
        <div class="py-6 flex w-full bg-img" v-if="categories.length === 0 && !errorFetching">
          <div
            class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
            <!--          <img src="@/assets/images/pages/svg_blank.svg" :alt="$t('NoData')"-->
            <!--               class="mx-auto mb-4 max-w-full">-->
            <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('NoData') }}</h1>
          </div>
        </div>
      </transition>

      <transition name="fade">
        <div class="flex flex-wrap gap-x-6 gap-y-6">
          <a
            v-for="(cat, index) of categories"
            class="flex cursor-pointer flex-row space-x-2 justify-between p-4 min-w-[250px] max-w-sm bg-white rounded-lg border border-gray-200 shadow-md hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5
              @click="openCategoryProductsPage(cat)"
              class="text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              {{ cat.title }}
            </h5>
            <div class="flex flex-row">
              <button type="button"
                      @click="editCategory(cat)"
                      class="text-white bg-primary font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                     fill="currentColor">
                  <path
                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"/>
                </svg>
              </button>
              <button type="button"
                      @click="deleteCategoryConfirm(cat)"
                      class="text-white bg-danger font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20"
                     fill="currentColor">
                  <path fill-rule="evenodd"
                        d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"/>
                </svg>
              </button>
            </div>
          </a>

        </div>
      </transition>

      <vx-modal @close="closeAddCategoryPage()" v-if="showAddCategoryModal"
                component-class="modal-container-fit">
        <template slot="body">
          <div>
            <div class=" mb-4"
                 style="font-size: 24px;font-family: Montserrat, Helvetica, Arial, sans-serif;font-weight: 500;line-height: 1.2;color: #2c2c2c;">

              <h1 class=" " style="text-align: center;">{{ $t('AddNewCategory') }}</h1>
            </div>
            <div class="py-4" style="text-align: center;">
              <div class="text-left">
                <label for="first_name"
                       class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">{{
                    $t('CategoryName')
                  }}</label>
                <input type="text" id="category_name"
                       v-model="categoryName"
                       class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder="Handy" required>
              </div>
            </div>

            <div class="flex flex-wrap justify-center mb-3 py-4">
              <vs-button class="mx-3" color="danger" type="border" @click="closeAddCategoryPage()">
                {{ $t('Cancel') }}
              </vs-button>
              <vs-button class="mx-3" v-if="selectedEditCategory" @click="updateCategory">{{ $t('UpdateCategory') }}</vs-button>
              <vs-button class="mx-3" v-else @click="createNewCategory">{{ $t('AddCategory') }}</vs-button>
            </div>
          </div>
        </template>
      </vx-modal>

    </div>

  </div>
</template>

<script>
import VxModal from "@/layouts/components/custom/VxModal";

export default {
  name: "AdminHardwareCategories",
  components: {VxModal},
  data() {
    return {
      errorFetching: false,
      showAddCategoryModal: false,
      categoryName: "",
      selectedEditCategory: null,
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Hardware', i18n: 'Hardware', active: true},
      ],
    }
  },
  computed: {
    categories() {
      return this.$store.state.hardware.categories
    },
  },
  methods: {
    openAddCategoryPage() {
      this.showAddCategoryModal = true
    },
    closeAddCategoryPage() {
      this.categoryName = '';
      this.selectedEditCategory = null;
      this.showAddCategoryModal = false
    },
    editCategory(cat) {
      this.categoryName = cat.title;
      this.selectedEditCategory= cat.id;
      this.openAddCategoryPage();
    },
    openCategoryProductsPage(cat) {
      this.$router.push({name: 'hardware-category', params: {categoryId: cat.id}})

    },
    createNewCategory() {
      if (!this.categoryName) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('PleaseEnterCategoryName'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return;
      }
      this.showAddCategoryModal = false
      this.$vs.loading()
      this.$store.dispatch('hardware/createNewHardwareCategory', {title: this.categoryName})
        .then(data => {
          this.categoryName = '';
          this.$vs.loading.close()
          this.fetchCategories();
        })
        .catch((error) => {
          this.categoryName = '';
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchCategories() {
      this.$vs.loading()
      this.$store.dispatch('hardware/fetchHardwareCategories', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    deleteCategoryConfirm(cat) {
      if (confirm(`Are you sure you want to delete ${cat.title}`) == true) {
        this.deleteCategory(cat.id)
      }
    },
    updateCategory() {
      if (!this.categoryName) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('PleaseEnterCategoryName'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return;
      }
      this.$vs.loading()
      let data = {
        title: this.categoryName,
        id: this.selectedEditCategory,
      }
      this.closeAddCategoryPage();
      this.$store.dispatch('hardware/updateHardwareCategory', data)
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchCategories();
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    deleteCategory(catId) {
      this.$vs.loading();
      this.$store.dispatch('hardware/deleteCategory', {id: catId})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.fetchCategories();
        })
        .catch((error) => {
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    }
  },
  mounted() {
    this.fetchCategories();
  },
}
</script>

<style scoped>

</style>
